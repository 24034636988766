<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_edit-tes-fed">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modifica tesseramento</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <div
                class="mx-auto my-auto text-center py-5 my-5"
                v-if="isLoadingDetail"
              >
                <div class="spinner-border text-gray-600" role="status"></div>
                <span class="text-gray-600 ps-3 fs-2">Loading</span>
              </div>
              <div class="alert alert-danger" role="alert" v-if="alertCampiObb">
                Compila i campi obbligatori
              </div>
              <div class="ps-5 pe-5" v-if="tesserato.id">
                <div class="row">
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Stagione</label>
                    <TextInput
                      name="stagione"
                      type="text"
                      :value="tesserato.stagione"
                      disabled
                    />
                  </div>
                  <div class="col-md-5">
                    <label class="fw-bold text-gray-600">Tesserato</label>
                    <div class="dp__input_wrap">
                      <TextInput
                        name="nominativo_completo"
                        type="text"
                        :value="tesserato.nominativo_completo"
                        disabled
                      />
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        class="dp__icon dp__clear_icon dp__input_icons"
                      >
                        <path
                          d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                        ></path>
                        <path
                          d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Numero albo</label>
                    <TextInput
                      name="numero_albo"
                      type="text"
                      :value="
                        tesserato.numero_albo == 0 ? '' : tesserato.numero_albo
                      "
                      disabled
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Tipo tessera</label>
                    <TextInput
                      name="tipo_tessera"
                      type="text"
                      :value="tesserato.tipo_tessera"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Emissione</label>
                    <button
                      @click.prevent="
                        isOpenDatepicker = !isOpenDatepicker;
                        openDatepicker(isOpenDatepicker);
                      "
                      class="bugSolver"
                    ></button>
                    <Datepicker
                      v-model="tesserato.data_emissione"
                      ref="datepickerBug"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      @update:model-value="
                        $event
                          ? (tesserato.data_emissione =
                              fromUTCtoISODate($event))
                          : ''
                      "
                    />
                    <Field
                      name="data_Emissione_Da"
                      type="hidden"
                      class="form-control"
                      v-model="tesserato.data_emissione"
                    />
                    <ErrorMessage name="data_Emissione_Da" style="color: red" />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Scadenza</label>
                    <Datepicker
                      v-model="tesserato.data_scadenza"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      disabled
                      @update:model-value="
                        $event
                          ? (tesserato.data_scadenza = fromUTCtoISODate($event))
                          : ''
                      "
                    />
                    <Field
                      name="data_Emissione_A"
                      type="hidden"
                      class="form-control"
                      v-model="tesserato.data_scadenza"
                    />
                    <ErrorMessage name="data_Emissione_A" style="color: red" />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Certificato</label>
                    <div class="pt-2">
                      <TextInput
                        name="certificato"
                        type="checkbox"
                        :checked="tesserato.certificato"
                        @change="
                          setCertificato($event.target.value);
                          tesserato.data_certificato = '';
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600"
                      >Data scadenza certificato</label
                    >
                    <Datepicker
                      :minDate="new Date()"
                      v-model="tesserato.data_certificato"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      @update:model-value="
                        $event
                          ? (tesserato.data_certificato =
                              fromUTCtoISODate($event))
                          : ''
                      "
                    />
                    <Field
                      name="data_Scadenza_Certificato"
                      type="hidden"
                      class="form-control"
                      v-model="tesserato.data_certificato"
                    />
                    <ErrorMessage
                      name="data_Scadenza_Certificato"
                      style="color: red"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <span
              type="button"
              class="badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalEditTesFed"
            >
              Chiudi
            </span>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { ref, watch } from "vue";
import Datepicker from "vue3-date-time-picker";
import TextInput from "../../utility/inputs/TextInput.vue";
import {
  useFormatDateYMD,
  fromUTCtoISODate,
  format,
} from "@/composables/formatDate";
import { editTesseratiFederali } from "@/requests/tesseratiFederaliRequest";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { viewTesseratiFederali } from "@/requests/tesseratiFederaliRequest";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "edit-tesserato-federale",
  components: { Loading, TextInput, Datepicker, Form, Field, ErrorMessage },
  props: {
    idTesserato: {
      type: Number,
      required: true,
      default: null,
    },
  },
  emits: ["refreshTesFedList"],
  setup(props, { emit }) {
    const datepickerBug = ref(null);
    const isOpenDatepicker = ref(false);
    const openDatepicker = (bool) => {
      if (bool) {
        datepickerBug.value.openMenu();
        return;
      }
      datepickerBug.value.closeMenu();
    };

    const tesserato = ref({});

    //richiesta Api
    const isLoadingDetail = ref(false);
    const getTesserato = async (id) => {
      tesserato.value = {};
      isLoadingDetail.value = true;
      await viewTesseratiFederali(id).then((res) => {
        if (res) {
          res.data_emissione = res.data_emissione
            ? useFormatDateYMD(res.data_emissione)
            : null;
          res.data_scadenza = res.data_scadenza
            ? useFormatDateYMD(res.data_scadenza)
            : null;
          res.data_certificato;
          tesserato.value = res;
        }
      });
      isLoadingDetail.value = false;
    };
    watch(
      () => props.idTesserato,
      async (id) => {
        await getTesserato(id);
      }
    );

    const setCertificato = (event) => {
      if (event === "true") {
        tesserato.value.certificato = 1;
      } else {
        tesserato.value.certificato = 0;
      }
    };

    // validazione e richiesta add
    const schema = yup.object().shape({
      data_Emissione_Da: yup.string().required("Obbligatorio").nullable(),
      data_Emissione_A: yup.string().required("Obbligatorio").nullable(),
      certificato: yup
        .bool()
        .transform((value) => (value == "" ? false : value)),
      data_Scadenza_Certificato: yup.string().when("certificato", {
        is: (certificato) => {
          return certificato === true;
        },
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const alertCampiObb = ref(false);

    const onSubmit = (values) => {
      alertCampiObb.value = false;
      disableButton.value = true;
      isLoading.value = true;
      values = {
        ...values,
        id_Comitato_Regionale: tesserato.value.id_comitato_regionale,
        id: tesserato.value.id,
      };
      const responseAdd = ref({});
      responseAdd.value = editTesseratiFederali(values);
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          alertSuccess("Tesserato modificato con successo");
          document.getElementById("closeModalEditTesFed").click();
          emit("refreshTesFedList");
        } else {
          alertFailed(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    return {
      isOpenDatepicker,
      openDatepicker,
      tesserato,
      format,
      onSubmit,
      onInvalidSubmit,
      setCertificato,
      schema,
      fromUTCtoISODate,
      disableButton,
      isLoading,
      isLoadingDetail,
      alertCampiObb,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
