import services from "../axios/dbManag";

export const viewTesseratiFederali = (id) => {
  return services.dbManag
    .post("/tesseramento/tesserati-federali/view", {
      id,
    })
    .then((res) => {
      const tesserato = res.data.results[0];

      return tesserato;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addTestFed = (tesserato) => {
  return services.dbManag
    .post("/tesseramento/tesserati-federali/add", {
      ...tesserato,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editTesseratiFederali = (tesserato) => {
  return services.dbManag
    .post("/tesseramento/tesserati-federali/edit", {
      ...tesserato,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteTesseramento = (id_Tesseramento, id_Persona) => {
  return services.dbManag
    .post("/tesseramento/tesserati-federali/del", {
      id_Tesseramento,
      id_Persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
