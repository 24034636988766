<template>
  <div>
    <div class="card shadow">
      <div
        class="pt-4 pb-4 pe-4 ps-4"
        @keyup.enter="$emit('getTesseratiFederaliList')"
      >
        <div class="row">
          <div class="col-sm-4 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Cognome"
              aria-label=""
              :value="cognomeTesserato"
              @input="setCognomeTesserato"
            />
          </div>
          <div class="col-sm-4 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              aria-label=""
              :value="nomeTesserato"
              @input="setNomeTesserato"
            />
          </div>

          <!--  <div class="col-sm-3 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Numero Albo"
              aria-label=""
              :value="numeroAlboTesserato"
              @input="setNumeroAlboTesserato"
            />
          </div> -->
          <div class="col-sm-4">
            <SelectInput
              :options="tesseramento_federale_tipo_categorie"
              name="tesseramento_federale_tipo_categorie"
              placeholder="Tipo qualifica"
              :value="idQualifica"
              @changeSelect="setIdQualifica"
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <span
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFilterTesseratiFederali"
            aria-expanded="false"
            aria-controls="collapseExample"
            class="badge rounded-pill bg-light text-gray-600"
            @click="isActive = !isActive"
            >Filtri avanzati
            <i
              :class="{
                'fas fa-angle-up fs-6 text-gray-600': isActive,
                'fas fa-angle-down fs-6 text-gray-600': !isActive,
              }"
            >
            </i
          ></span>
          <button
            v-if="!isActive"
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('getTesseratiFederaliList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            v-if="!isActive"
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>

        <div class="collapse" id="collapseFilterTesseratiFederali">
          <div class="bg-light rounded-3">
            <div class="row mt-4 pb-4 pt-4 ps-2 pe-2">
              <div class="col-sm-3 filter">
                <SelectInput
                  placeholder="Comitato"
                  :options="comitati"
                  :value="idComitatoRegionale"
                  name="id_periferico"
                  @changeSelect="setComitato"
                />
              </div>
              <div class="col-sm-3 filter">
                <Datepicker
                  v-model="dataEmissioneDa"
                  placeholder="Inizio Tesseramento"
                  :format="format"
                  :monthChangeOnScroll="false"
                  :enableTimePicker="false"
                  inputClassName="h-28"
                  @update:modelValue="setDataEmissioneDa($event)"
                  autoApply
                />
              </div>
              <div class="col-sm-3">
                <Datepicker
                  v-model="dataEmissioneA"
                  placeholder="Fine Tesseramento"
                  :format="format"
                  :monthChangeOnScroll="false"
                  :enableTimePicker="false"
                  inputClassName="h-28"
                  @update:modelValue="setDataEmissioneA($event)"
                  autoApply
                />
              </div>
            </div>
          </div>
          <div class="text-center mt-4">
            <span
              v-if="isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getTesseratiFederaliList')"
              :disabled="!loaded"
              >Cerca <i class="bi bi-search fs-6 text-gray-600"> </i
            ></span>
            <span
              v-if="isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click.prevent="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import {
  defineComponent,
  computed,
  ref,
  watch,
  getCurrentInstance,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { useFormatDateYMD, format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtriTesseratiFederali",
  emits: ["resetFilters", "getTesseratiFederaliList"],
  props: {},
  components: {
    Datepicker,
    SelectInput,
  },

  setup(props, { emit }) {
    const store = useStore();
    const isActive = ref(false);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const tesseramento_federale_tipo_categorie = computed(() =>
      store.getters.getStateFromName("tesseramento_federale_tipo_categorie")
    );
    const comitati = computed(() => store.getters.getStateFromName("comitati"));

    const keys = ref("c|tftc");
    if (!comitati.value || !tesseramento_federale_tipo_categorie.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getTesseratiFederaliList");
    });

    const nomeTesserato = computed(() => store.getters.nomeTesseratoFederale);
    const cognomeTesserato = computed(
      () => store.getters.cognomeTesseratoFederale
    );
    const numeroAlboTesserato = computed(
      () => store.getters.numeroAlboTesseratoFederale
    );
    const idQualifica = computed(
      () => store.getters.idQualificaTesseratoFederale
    );
    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleTesseratoFederale
    );
    const dataEmissioneDa = computed(
      () => store.getters.dataEmissioneDaTesseratoFederale
    );
    const dataEmissioneA = computed(
      () => store.getters.dataEmissioneATesseratoFederale
    );

    const setNomeTesserato = (e) => {
      const string = e.target.value;
      store.commit("setNomeTesseratoFederale", string);
    };
    const setCognomeTesserato = (e) => {
      const string = e.target.value;
      store.commit("setCognomeTesseratoFederale", string);
    };
    const setNumeroAlboTesserato = (e) => {
      const string = e.target.value;
      store.commit("setNumeroAlboTesseratoFederale", string);
    };
    const setIdQualifica = (event) => {
      store.commit("setIdQualificaTesseratoFederale", event);
    };
    const setComitato = (event) => {
      store.commit("setIdComitatoRegionaleTesseratoFederale", event);
    };
    const setDataEmissioneDa = (event) => {
      store.commit("setDataEmissioneDaTesseratoFederale", event);
    };
    const setDataEmissioneA = (event) => {
      store.commit("setDataEmissioneATesseratoFederale", event);
    };

    // verifico se le lookup esistono, altrimanti le carico da api
    onMounted(() => {
      if (
        idComitatoRegionale.value ||
        dataEmissioneDa.value ||
        dataEmissioneA.value
      ) {
        isActive.value = true;
        document
          .getElementById("collapseFilterTesseratiFederali")
          .classList.add("show");
      }
    });

    return {
      isActive,
      nomeTesserato,
      cognomeTesserato,
      setNomeTesserato,
      setCognomeTesserato,
      numeroAlboTesserato,
      setNumeroAlboTesserato,
      idQualifica,
      setIdQualifica,
      comitati,
      idComitatoRegionale,
      setComitato,
      dataEmissioneDa,
      dataEmissioneA,
      setDataEmissioneDa,
      setDataEmissioneA,
      tesseramento_federale_tipo_categorie,
      useFormatDateYMD,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtesserati_federali_list")
      ),
    };
  },
});
</script>

<style></style>
