<template>
  <div>
    <FilterFederalMembers
      @getTesseratiFederaliList="searchTesseratoFederaleList"
      @resetFilters="resetFilters"
    />
    <TableFederalMembers
      @getTesseratiFederaliList="getTesseratiFederaliList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FilterFederalMembers from "@/components/components-fit/tesserati/tesserati-federali/FiltriTesseratiFederali.vue";
import TableFederalMembers from "@/components/components-fit/tesserati/tesserati-federali/TableTesseratiFederali.vue";
import { trimInput } from "@/composables/trimInput";

export default defineComponent({
  name: "federal-members",
  components: {
    FilterFederalMembers,
    TableFederalMembers,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Tessere Speciali/UDG", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const startDateTessFed = computed(
      () => store.getters.dataEmissioneDaTesseratoFederale
    );
    const endDateTessFed = computed(
      () => store.getters.dataEmissioneATesseratoFederale
    );

    const rowsToSkip = computed(
      () => store.getters.rowsToSkipTesseratoFederale
    );
    const fetchRows = computed(() => store.getters.fetchRowsTesseratoFederale);
    const sortColumn = computed(
      () => store.getters.sortColumnTesseratoFederale
    );
    const sortOrder = computed(() => store.getters.sortOrderTesseratoFederale);

    const id_Persona = computed(() => store.getters.idPersonaTesseratoFederale);
    const cognome = computed(() => store.getters.cognomeTesseratoFederale);
    const nome = computed(() => store.getters.nomeTesseratoFederale);
    const idQualifica = computed(
      () => store.getters.idQualificaTesseratoFederale
    );
    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleTesseratoFederale
    );

    const getTesseratiFederaliList = () => {
      const localKeys = {
        id_Persona: id_Persona.value,
        stagione: anno.value,
        cognome: trimInput(cognome.value),
        nome: trimInput(nome.value),
        id_Qualifica: idQualifica.value,
        id_Comitato_Regionale: idComitatoRegionale.value,
        data_Emissione_Da: startDateTessFed.value,
        data_Emissione_A: endDateTessFed.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      /*  */
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.TESSERATI_FEDERALI_LIST,
        itemName: "tesserati_federali_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersTesseratiFederali");
      getTesseratiFederaliList();
    };
    getTesseratiFederaliList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedtesserati_federali_list")
    );

    const searchTesseratoFederaleList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipTesseratoFederale");
      getTesseratiFederaliList();
    };

    return {
      getTesseratiFederaliList,
      resetFilters,
      searchTesseratoFederaleList,
    };
  },
});
</script>
