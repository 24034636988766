<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_view-tes-fed">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Tesseramento</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div
            class="mx-auto my-auto text-center py-5 my-5"
            v-if="isLoadingDetail"
          >
            <div class="spinner-border text-gray-600" role="status"></div>
            <span class="text-gray-600 ps-3 fs-2">Loading</span>
          </div>
          <div class="card-body p-6" v-if="!isLoadingDetail">
            <div class="ps-5 pe-5">
              <div class="row">
                <div class="col-md-3">
                  <label class="fw-bold text-gray-600">Stagione</label>
                  <TextInput
                    name="stagione"
                    type="text"
                    :value="tesserato.stagione"
                    disabled
                  />
                </div>
                <div class="col-md-5">
                  <label class="fw-bold text-gray-600">Tesserato</label>
                  <div class="dp__input_wrap">
                    <TextInput
                      name="nominativo_completo"
                      type="text"
                      :value="tesserato.nominativo_completo"
                      disabled
                    />
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      class="dp__icon dp__clear_icon dp__input_icons"
                    >
                      <path
                        d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                      ></path>
                      <path
                        d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="fw-bold text-gray-600">Numero albo</label>
                  <TextInput
                    name="numero_albo"
                    type="text"
                    :value="
                      tesserato.numero_albo == 0 ? '' : tesserato.numero_albo
                    "
                    disabled
                  />
                </div>
              </div>
              <div class="row pb-4">
                <div class="col-md-3">
                  <label class="fw-bold text-gray-600">Tipo tessera</label>
                  <TextInput
                    name="numero_albo"
                    type="text"
                    :value="tesserato.tipo_tessera"
                    disabled
                  />
                </div>
                <div class="col-md-3">
                  <label class="fw-bold text-gray-600">Emissione</label>
                  <Datepicker
                    v-model="data_emissione"
                    placeholder=""
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                    disabled
                  />
                </div>
                <div class="col-md-3">
                  <label class="fw-bold text-gray-600">Scadenza</label>
                  <Datepicker
                    v-model="data_scadenza"
                    placeholder=""
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                    disabled
                  />
                </div>
              </div>
              <div class="row pb-4" v-show="data_certificato">
                <div class="col-md-3">
                  <label class="fw-bold text-gray-600">Certificato</label>
                  <div class="pt-2">
                    <TextInput
                      name="certificato"
                      type="checkbox"
                      :checked="tesserato.certificato"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="fw-bold text-gray-600"
                    >Data scadenza certificato</label
                  >
                  <Datepicker
                    disabled
                    v-model="data_certificato"
                    placeholder=""
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { computed } from "vue";
import Datepicker from "vue3-date-time-picker";
import TextInput from "../../utility/inputs/TextInput.vue";
//import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { useStore } from "vuex";
import { useFormatDateYMD, format } from "@/composables/formatDate";

export default {
  name: "view-tesserato-federale",
  components: { TextInput, Datepicker },
  props: {
    tesserato: {
      type: Object,
    },
    isLoadingDetail: {
      type: Boolean,
    },
  },
  setup(props) {
    const store = useStore();
    const data_emissione = computed(() =>
      props.tesserato.data_emissione
        ? useFormatDateYMD(props.tesserato.data_emissione)
        : null
    );
    const data_scadenza = computed(() =>
      props.tesserato.data_scadenza
        ? useFormatDateYMD(props.tesserato.data_scadenza)
        : null
    );
    const data_certificato = computed(() => props.tesserato.data_certificato);

    return {
      data_emissione,
      data_scadenza,
      data_certificato,
      stagioni: computed(() => store.getters.stagioni),
      format,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
