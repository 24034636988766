<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add-tes-fed">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inserimento nuovo tesserato</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row mb-4">
                    <div class="col-sm-7 filter">
                      <div class="dp__input_wrap">
                        <label class="fw-bold text-gray-600">Persona*</label>
                        <input
                          name="nome_persona"
                          type="text"
                          class="form-control"
                          autocomplete="off"
                          @input="
                            getTesserati($event.target.value);
                            nome_persona === ''
                              ? (id_persona = null)
                              : (id_persona = 0);
                          "
                          v-model="nome_persona"
                        />
                        <Field
                          name="id_persona"
                          type="hidden"
                          v-model="id_persona"
                          class="form-control"
                        />
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="spliceTesserati()"
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListTesserati
                        :list="tesseratiList"
                        @hideList="resetTesserati"
                        @selectedTesserato="
                          resetTesserati();
                          nome_persona = $event.name;
                          id_persona = $event.value;
                        "
                      ></ListTesserati>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_persona"
                      />
                    </div>
                    <div class="col-md-5">
                      <label class="fw-bold text-gray-600"
                        >Tipo qualifica*</label
                      >
                      <SelectInput
                        :options="tesseramento_federale_tipo_categorie"
                        name="id_Qualifica"
                        :value="id_Qualifica"
                        @changeSelect="setid_Qualifica($event)"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600">Comitato*</label>
                      <SelectInput
                        :options="comitati"
                        name="id_Comitato_Regionale"
                        :value="id_Comitato_Regionale"
                        @changeSelect="setid_Comitato_Regionale($event)"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600">Emissione*</label>
                      <Datepicker
                        v-model="data_Emissione_Da"
                        @update:modelValue="
                          $event
                            ? (data_Emissione_Da = fromUTCtoISODate($event))
                            : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        readonly
                      />
                      <Field
                        name="data_Emissione_Da"
                        type="hidden"
                        v-model="data_Emissione_Da"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Emissione_Da"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600">Scadenza*</label>
                      <Datepicker
                        v-model="data_Emissione_A"
                        @update:modelValue="
                          $event
                            ? (data_Emissione_A = fromUTCtoISODate($event))
                            : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        readonly
                      />
                      <Field
                        name="data_Emissione_A"
                        type="hidden"
                        v-model="data_Emissione_A"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Emissione_A"
                      />
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Certificato</label>
                      <div class="pt-2">
                        <TextInput
                          name="certificato"
                          :value="certificato"
                          type="checkbox"
                          @changeEvent="setCertificato($event)"
                          :checked="certificato"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data scadenza*</label
                      >
                      <Datepicker
                        :minDate="new Date()"
                        v-model="data_Scadenza_Certificato"
                        @update:modelValue="
                          $event
                            ? (data_Scadenza_Certificato =
                                fromUTCtoISODate($event))
                            : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        :disabled="certificato ? false : true"
                      />
                      <Field
                        name="data_Scadenza_Certificato"
                        type="hidden"
                        v-model="data_Scadenza_Certificato"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Scadenza_Certificato"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Tipo pagamento*</label
                      >
                      <SelectInput
                        :options="pagamento_tipo"
                        name="id_tipo_pagamento"
                        :value="id_tipo_pagamento"
                        @changeSelect="setid_tipo_pagamento($event)"
                        :disabled="id_Qualifica == 7 ? false : true"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Banca / agenzia*</label
                      >
                      <div>
                        <Field
                          name="banca"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          v-model="banca"
                          :disabled="
                            id_tipo_pagamento == 6 ||
                            id_Qualifica != 7 ||
                            id_tipo_pagamento == null
                          "
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="banca"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Riferimenti*</label>
                      <div>
                        <Field
                          name="riferimenti"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          v-model="riferimenti"
                          :disabled="
                            id_tipo_pagamento == 6 ||
                            id_Qualifica != 7 ||
                            id_tipo_pagamento == null
                          "
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="riferimenti"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data pagamento*</label
                      >
                      <Datepicker
                        v-model="data_pagamento"
                        @update:modelValue="
                          $event
                            ? (data_pagamento = fromUTCtoISODate($event))
                            : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        :disabled="
                          id_tipo_pagamento == 6 ||
                          id_Qualifica != 7 ||
                          id_tipo_pagamento == null
                        "
                      />
                      <Field
                        name="data_pagamento"
                        type="hidden"
                        v-model="data_pagamento"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_pagamento"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalTesFed"
            >
              Chiudi
            </button>
            <button
              type="reset"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { fromUTCtoISODate, format } from "@/composables/formatDate";

import { addTestFed } from "@/requests/tesseratiFederaliRequest";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "addTesFed",
  components: {
    Loading,
    ListTesserati,
    Form,
    SelectInput,
    Datepicker,
    ErrorMessage,
    Field,
    TextInput,
  },
  emits: ["refreshTesFedList"],
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const tesseramento_federale_tipo_categorie = computed(() =>
      store.getters.getStateFromName("tesseramento_federale_tipo_categorie")
    );

    const keys = ref("tftc");
    if (!tesseramento_federale_tipo_categorie.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const getComitati = () => {
      store.dispatch("setStoreListData", {
        apiLink: globalApi.LOOKUP_COMITATI,
        itemName: "comitati_list",
      });
    };
    getComitati();

    const alertCampiObb = ref(false);

    const stagione = computed(() => store.getters.defaultStagione);

    const nome_persona = ref(null);
    const id_persona = ref(null);

    const numero_Albo = ref(null);

    const pagamento_tipo = computed(() =>
      store.getters.getStateFromName("pagamento_tipo")
    );

    const id_Qualifica = ref(null);
    const setid_Qualifica = (event) => {
      id_Qualifica.value = parseInt(event);
      id_tipo_pagamento.value = null;
      if (id_Qualifica.value == 7) {
        store.dispatch("setStoreData", {
          apiLink: globalApi.TIPO_PAGAMENTI_FED_LOOKUP,
        });
      }
    };

    const id_Comitato_Regionale = ref(null);
    const setid_Comitato_Regionale = (event) => {
      id_Comitato_Regionale.value = parseInt(event);
    };

    const data_Emissione_Da = ref(fromUTCtoISODate(new Date()));

    const data_Emissione_A = ref(new Date().getFullYear() + 1 + "-03-31");

    const certificato = ref(false);
    const setCertificato = (event) => {
      certificato.value = event;
      if (event == false) {
        data_Scadenza_Certificato.value = "";
      }
    };

    const data_Scadenza_Certificato = ref(null);

    const id_tipo_pagamento = ref(null);
    const setid_tipo_pagamento = (event) => {
      id_tipo_pagamento.value = event;
      if (id_tipo_pagamento.value == 6) {
        banca.value = "";
        riferimenti.value = "";
        data_pagamento.value = "";
      }
    };

    const banca = ref(null);

    const riferimenti = ref(null);

    const data_pagamento = ref(null);

    const getTesserati = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceTesserati = () => {
      nome_persona.value = "";
      id_persona.value = null;
      resetTesserati();
      id_Qualifica.value = null;
      numero_Albo.value = null;
      id_Comitato_Regionale.value = null;
      data_Emissione_Da.value = fromUTCtoISODate(new Date());
      data_Emissione_A.value = new Date().getFullYear() + 1 + "-03-31";
      certificato.value = false;
      data_Scadenza_Certificato.value = null;
      id_tipo_pagamento.value = null;
      banca.value = null;
      riferimenti.value = null;
      data_pagamento.value = null;
    };

    const schema = yup.object().shape({
      id_persona: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      /*     numero_Albo: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(), */
      id_Qualifica: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      data_Emissione_Da: yup.string().required("Obbligatorio").nullable(),
      data_Emissione_A: yup.string().required("Obbligatorio").nullable(),
      id_Comitato_Regionale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      certificato: yup.bool(),
      data_Scadenza_Certificato: yup.string().when("certificato", {
        is: (certificato) => {
          return certificato === true;
        },
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      id_tipo_pagamento: yup.number().when("id_Qualifica", {
        is: (id_Qualifica) => {
          return id_Qualifica === 7;
        },
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      banca: yup.string().when("id_tipo_pagamento", {
        is: (id_tipo_pagamento) => {
          return id_tipo_pagamento != 6 && id_tipo_pagamento != null;
        },
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      riferimenti: yup.string().when("id_tipo_pagamento", {
        is: (id_tipo_pagamento) => {
          return id_tipo_pagamento != 6 && id_tipo_pagamento != null;
        },
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      data_pagamento: yup.string().when("id_tipo_pagamento", {
        is: (id_tipo_pagamento) => {
          return id_tipo_pagamento != 6 && id_tipo_pagamento != null;
        },
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      values = {
        ...values,
        stagione: stagione.value,
        id_qualifica_dirigente: 0,
      };
      const responseAdd = ref({});
      let messaggioResponse;
      responseAdd.value = addTestFed(values);
      messaggioResponse = "Tesserato Federale aggiunto correttamente.";
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: messaggioResponse,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          resetField();
          document.getElementById("closeModalTesFed").click();
          emit("refreshTesFedList");
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      spliceTesserati();
      id_Qualifica.value = null;
      numero_Albo.value = null;
      id_Comitato_Regionale.value = null;
      data_Emissione_Da.value = fromUTCtoISODate(new Date());
      data_Emissione_A.value = new Date().getFullYear() + 1 + "-03-31";
      certificato.value = false;
      data_Scadenza_Certificato.value = null;
      id_tipo_pagamento.value = null;
      banca.value = null;
      riferimenti.value = null;
      data_pagamento.value = null;
      nome_persona.value = null;
      id_persona.value = null;
    };

    return {
      stagione,
      numero_Albo,
      id_Qualifica,
      setid_Qualifica,
      id_Comitato_Regionale,
      setid_Comitato_Regionale,
      data_Emissione_Da,
      data_Emissione_A,
      certificato,
      setCertificato,
      data_Scadenza_Certificato,
      id_tipo_pagamento,
      setid_tipo_pagamento,
      banca,
      riferimenti,
      data_pagamento,
      nome_persona,
      id_persona,
      tesseramento_federale_tipo_categorie,
      comitati: computed(() =>
        store.getters.getStateFromName("resultscomitati_list")
      ),
      getTesserati,
      resetTesserati,
      spliceTesserati,
      tesseratiList: computed(() => store.getters.personeSocieta),
      pagamento_tipo,
      schema,
      onSubmit,
      onInvalidSubmit,
      resetField,
      alertCampiObb,
      fromUTCtoISODate,
      format,
      disableButton,
      isLoading,
    };
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}

.dp__clear_icon {
  top: 68%;
}
</style>
